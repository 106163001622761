<template>
	<div class="d-inline">
		<div class="task-tree-view">
			<ul>
				<template v-for="nodeData in data">
					<task-comment-tree-node
						ref="rootNodes"
						:key="nodeData[nodeKeyProp]"
						:data="nodeData"
						:keyProp="nodeKeyProp"
						:xidProp="nodeXidProp"
						:imageParentIdProp="nodeImageParentIdProp"
						:imageParentTypeProp="nodeImageParentTypeProp"
						:imageParentSubTypeProp="nodeImageParentSubTypeProp"
						:childrenProp="nodeChildrenProp"
						:detailProp="nodeDetailProp"
					/>
				</template>
			</ul>
		</div>
	</div>
</template>
<script>
import TaskCommentTreeNode from '@/components/Task/TaskCommentTreeNode';

export default {
	name: 'TaskCommentTreeView',
	components: {
		TaskCommentTreeNode
	},
	props: {
		data: {
			type: Array,
			required: true,
			default: () => []
		},
		nodeKeyProp: {
			type: String,
			default: 'id'
		},
		nodeXidProp: {
			type: String,
			default: 'xid'
		},
		nodeImageParentIdProp: {
			type: String,
			default: 'imageParentId'
		},
		nodeImageParentTypeProp: {
			type: String,
			default: 'imageParentType'
		},
		nodeImageParentSubTypeProp: {
			type: String,
			default: 'imageParentSubType'
		},
		nodeChildrenProp: {
			type: String,
			default: 'children'
		},
		nodeDetailProp: {
			type: String,
			default: 'detail'
		}
	}
};
</script>
