import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
import UserToMentioned from '@/components/Task/Mention/List';
import { getUsersOfProject } from '@/cruds/users.crud';
import { noTilde } from '~utils';

export default {
	items: async ({ query }) => {
		const rawUsers = await getUsersOfProject();
		if (rawUsers.length > 0) {
			rawUsers.sort((a, b) => {
				if (a.fullName < b.fullName) {
					return -1;
				}
				if (a.fullName > b.fullName) {
					return 1;
				}

				return 0;
			});
		}

		let users = [];
		rawUsers.forEach((rawUser) => {
			users.push(rawUser.fullName);
		});

		return users.filter((user) => noTilde(user).match(new RegExp(query, 'gi')));
	},
	render: () => {
		let component;
		let popup;

		return {
			onStart: (props) => {
				component = new VueRenderer(UserToMentioned, {
					parent: this,
					propsData: props,
					editor: props.editor
				});

				popup = tippy('body', {
					getReferenceClientRect: props.clientRect,
					appendTo: () => document.body,
					content: component.element,
					showOnCreate: true,
					interactive: true,
					trigger: 'manual',
					placement: 'bottom-start'
				});
			},
			onUpdate(props) {
				component.updateProps(props);
				popup[0].setProps({
					getReferenceClientRect: props.clientRect
				});
			},
			onKeyDown(props) {
				if (props.event.key === 'Escape') {
					popup[0].hide();

					return true;
				}

				return component.ref ? component.ref.onKeyDown(props) : false;
			},
			onExit() {
				popup[0].destroy();
				component.destroy();
			}
		};
	}
};
