var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-inline" }, [
    _c("div", { staticClass: "task-tree-view" }, [
      _c(
        "ul",
        [
          _vm._l(_vm.data, function (nodeData) {
            return [
              _c("task-comment-tree-node", {
                key: nodeData[_vm.nodeKeyProp],
                ref: "rootNodes",
                refInFor: true,
                attrs: {
                  data: nodeData,
                  keyProp: _vm.nodeKeyProp,
                  xidProp: _vm.nodeXidProp,
                  imageParentIdProp: _vm.nodeImageParentIdProp,
                  imageParentTypeProp: _vm.nodeImageParentTypeProp,
                  imageParentSubTypeProp: _vm.nodeImageParentSubTypeProp,
                  childrenProp: _vm.nodeChildrenProp,
                  detailProp: _vm.nodeDetailProp,
                },
              }),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }