<template>
	<div class="block-action-comment pb-2" v-if="item.commentType === 1 || (item.commentType === 0 && item.replyTo === 0)">
		<div class="row">
			<div class="col-sm-12">
				<div class="row p-2">
					<div class="col-sm-6 timeline-name-splitter-horizontal">
						<div class="row">
							<div class="mr-2 pl-2">
								<div v-if="item.picture && item.picture !== ''" v-lazy-container="{ selector: 'img' }" class="mb-2">
									<img
										:data-src="showPicture(item.picture)"
										class="assigned-user-image"
										alt="photo"
										data-error="img/brand/avatar.jpg"
										data-loading="img/brand/tenor.gif"
										style="width: 26px !important; height: 26px !important"
									/>
								</div>
								<div
									v-else
									:style="{
										backgroundColor: randomizeColor
									}"
									class="not-img"
								>
									<div>
										{{ initial }}
									</div>
								</div>
							</div>
							<div class="timeline-height-image">
								{{ item.firstName }} {{ item.name | upperCase }} <span v-if="item.commentType === 0">{{ commentText }}</span>
							</div>
						</div>
					</div>
					<div class="col-sm-6 timeline-date-splitter-horizontal">
						<div class="pull-right">{{ dateToLiteral }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div :class="`col-sm-12 px-4${item.commentType === 0 ? ' timeline-content' : ''}`">
				<b-row v-if="item.commentType === 0">
					<b-col sm="12">
						<!-- begin comment parent -->
						<task-block-comment-parent :comment="item" />
						<!-- end comment parent -->
						<!-- begin tree child -->
						<task-tree-comment-child :data="treeData" />
						<!-- end tree child -->
					</b-col>
				</b-row>
				<div class="timeline-text" v-else>
					{{ item.message }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import TaskBlockCommentParent from '@/components/Task/TaskBlockCommentParent';
import TaskTreeCommentChild from '@/components/Task/TaskTreeCommentChild';
import globalMixin from '@/mixins/global.mixin';
import { capitalize } from '~utils';
import moment from 'moment';

// treeData: [{
//     id: 1,
//     children: [{
//         id: 2,
//         detail: {
//             picture: "",
//             firstName: "Ralison",
//             name: "MANANJAONA",
//             insertedOn: "Mercredi 24 nov. 2021 à 11:23",
//             message: "<p>Hello</p>",
//             isModified: false
//         },
//     }, {
//         id: 3,
//         detail: {
//             picture: "",
//             firstName: "Avana",
//             name: "MANANJAONA",
//             insertedOn: "Mercredi 24 nov. 2021 à 11:23",
//             message: "<p>World</p>",
//             isModified: false
//         },
//         children: [{
//             id: 31,
//             detail: {
//                 picture: "",
//                 firstName: "Harena",
//                 name: "MANANJAONA",
//                 insertedOn: "Mercredi 24 nov. 2021 à 11:23",
//                 message: "<p>Cool !!</p>",
//                 isModified: false
//             },
//             children: [{
//                 id: 311,
//                 detail: {
//                     picture: "",
//                     firstName: "Honintsoa",
//                     name: "LALAO",
//                     insertedOn: "Mercredi 24 nov. 2021 à 11:23",
//                     message: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta justo ipsum, vel sodales massa porta id.</p>",
//                     isModified: false
//                 },
//             }]
//         }]
//     }, {
//         id: 6,
//         detail: {
//             picture: "",
//             firstName: "Thierry",
//             name: "HERMANS",
//             insertedOn: "Mercredi 24 nov. 2021 à 11:23",
//             message: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta justo ipsum, vel sodales massa porta id.</p>",
//             isModified: false
//         },
//     }]
// }]

export default {
	name: 'TaskBlockCommentAction',
	mixins: [languageMessages, globalMixin],
	props: {
		item: {
			type: Object,
			required: true,
			default: null
		},
		treeData: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	components: {
		TaskBlockCommentParent,
		TaskTreeCommentChild
	},
	computed: {
		dateToLiteral() {
			const langCurrent = this.currentLang;
			const fullLiteralDate = capitalize(
				moment(this.item.insertedOn)
					.locale(`${langCurrent}`)
					.format(`dddd Do MMM YYYY [${this.FormMSG(2, 'at')}] HH:mm`)
			);

			return fullLiteralDate;
		},
		commentText() {
			return `${this.FormMSG(1, 'has commented')}`;
		},
		initial() {
			return this.item.firstName.charAt(0).toUpperCase();
		}
	},
	methods: {
		showPicture(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		}
	},
	filters: {
		upperCase(texte) {
			return texte.toUpperCase();
		}
	}
};
</script>
<style lang="scss">
.not-img {
	display: inline-block;
	border-radius: 26px;
	width: 26px;
	height: 26px;
	color: white;
	position: relative;
	> div {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		justify-content: center;
		align-items: center;
		display: flex;
	}
}
</style>
