var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    [
      _vm.treeData[_vm.detailProp]
        ? _c("task-block-comment-child", {
            attrs: { detail: _vm.treeData[_vm.detailProp] },
          })
        : _vm._e(),
      _vm.treeData[_vm.childrenProp] &&
      Array.isArray(_vm.treeData[_vm.childrenProp])
        ? _c(
            "ul",
            [
              _vm._l(_vm.treeData[_vm.childrenProp], function (nodeData) {
                return [
                  _c("task-comment-tree-node", {
                    key: nodeData[_vm.keyProp],
                    ref: "childNodes",
                    refInFor: true,
                    attrs: {
                      data: nodeData,
                      keyProp: _vm.keyProp,
                      xidProp: _vm.xidProp,
                      detailProp: _vm.detailProp,
                      childrenProp: _vm.childrenProp,
                    },
                  }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }