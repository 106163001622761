<template>
	<div class="row">
		<div class="col-11">
			<div v-html="comment.message"></div>
			<div>
				<task-comment-like-reply :comment="comment" :is-root="true" />
			</div>
			<div class="pb-4" />
		</div>
		<div class="col-1 timeline-comment-action">
			<div class="d-flex flex-row">
				<div class="col-12 timeline-cursor">
					<div class="pull-right">
						<i class="fa fa-ellipsis-h" aria-hidden="true"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import TaskCommentLikeReply from '@/components/Task/TaskCommentLikeReply';

export default {
	name: 'TaskBlockCommentParent',
	mixins: [languageMessages, globalMixin],
	components: {
		TaskCommentLikeReply
	},
	props: {
		comment: {
			type: Object,
			required: true,
			default: null
		}
	}
};
</script>
<style lang="scss" scoped></style>
