var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.comments.length === 0
        ? _c("div", { staticClass: "text-center" }, [
            _vm._v(
              "\n\t\t" + _vm._s(_vm.FormMSG(89765, "No comments")) + "\n\t"
            ),
          ])
        : _vm._e(),
      _vm._l(_vm.comments, function (comment) {
        return [
          _c("task-block-comment-action", {
            key: comment.id,
            attrs: { item: comment, "tree-data": comment.children },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }