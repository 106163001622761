<template>
	<div>
		<div v-if="comments.length === 0" class="text-center">
			{{ FormMSG(89765, 'No comments') }}
		</div>
		<template v-for="comment in comments">
			<task-block-comment-action :key="comment.id" :item="comment" :tree-data="comment.children" />
		</template>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { isNil } from '~utils';
// import globalMixin from '@/mixins/global.mixin';
import TaskBlockCommentAction from '@/components/Task/TaskBlockCommentAction';
import { getItemComments } from '@/cruds/itemcomment.crud';
import * as _ from 'lodash';

// comments: [{
//     id: 25,
//     insertedOn: '2022-02-16T09:34:11Z',
//     message: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta justo ipsum, vel sodales massa porta id. Donec ac commodo neque, vel tristique lorem. Integer sagittis sollicitudin lectus a pellentesque. Maecenas porta aliquam tellus a gravida.</p>',
//     commentType: 0,
//     replyTo: 0,
//     xid: 'c869looqlfg2cl0phppg',
//     name: 'Mananjaona',
//     firstName: 'Ralison',
//     picture: ''
// }],

export default {
	name: 'TaskComment',
	components: { TaskBlockCommentAction },
	mixins: [languageMessages],
	props: {
		imageParentType: {
			type: String,
			required: false,
			default: 'comment'
		},
		imageParentSubType: {
			type: String,
			required: false,
			default: 'images'
		},
		keyValue: {
			type: Object,
			required: true,
			default: () => ({
				xid: null,
				imageParentId: 0
			})
		},
		newComment: {
			type: Object,
			required: false,
			default: null
		}
	},
	data() {
		return {
			comments: [],
			rootId: 0
		};
	},
	watch: {
		newComment: {
			handler(comment) {
				if (!isNil(comment)) {
					this.comments.push(comment);
				}
			},
			deep: true,
			immediate: true
		},
		keyValue: {
			async handler(n) {
				if (!isNil(n) && !isNil(n.xid) && n.xid.length > 0 && !isNil(n.imageParentId)) {
					const data = await getItemComments(n.xid);
					let comments = [];
					for (const comment of data) {
						let node = comment;
						const children = await this.getTreeView(comment, parseInt(n.imageParentId, 10));
						this.$set(node, 'children', children);
						comments.push(node);
					}
					this.comments = comments;
				}
			},
			deep: true,
			immediate: true
		}
	},
	methods: {
		async getTreeView(parent, rootId, treeData = [], lastIndexes = []) {
			const initIndex = 0;
			if (parent.replyTo === 0) {
				treeData.push({
					id: parseInt(parent.id, 10),
					xid: parent.xid,
					imageParentId: rootId,
					imageParentType: this.imageParentType,
					imageParentSubType: this.imageParentSubType
				});
				lastIndexes.push(initIndex);
			}

			return await this.getDeepTree(parent, rootId, treeData, lastIndexes);
		},
		async getDeepTree(parent, rootId, treeData = [], lastIndexes = []) {
			const data = await getItemComments(parent.xid, parent.id);
			const currentLastIndexLength = lastIndexes.length;
			if (data.length > 0) {
				let getDeep = {};
				lastIndexes.forEach((lastIndex, i) => {
					if (i === 0) {
						getDeep = treeData[lastIndex];
					} else {
						getDeep = getDeep.children[lastIndex];
					}
				});
				this.$set(getDeep, 'children', []);
				let index = 0;
				for (const node of data) {
					if (lastIndexes.length > currentLastIndexLength) {
						lastIndexes.pop();
					}
					lastIndexes.push(index);
					getDeep.children.push({
						id: parseInt(node.id, 10),
						xid: node.xid,
						imageParentId: rootId,
						imageParentType: this.imageParentType,
						imageParentSubType: this.imageParentSubType,
						detail: node
					});

					//merge
					_.merge(treeData, getDeep);

					treeData = await this.getTreeView(node, rootId, treeData, lastIndexes);
					index++;
				}
			}

			return treeData;
		}
	}
};
</script>
