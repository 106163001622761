var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.mainClass },
    [
      _c("b-col", [
        _c(
          "div",
          { class: _vm.mainEditorClass },
          [
            _vm.editor
              ? _c("div", { class: _vm.secondElEditorClass }, [
                  _c(
                    "div",
                    { class: `d-flex flex-row ${_vm.thirdElEditorClass}` },
                    [
                      _c("div", [
                        _c(
                          "a",
                          {
                            class: { "is-active": _vm.editor.isActive("bold") },
                            on: {
                              click: function ($event) {
                                _vm.editor.chain().focus().toggleBold().run()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-bold",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", [
                        _c(
                          "a",
                          {
                            class: {
                              "is-active": _vm.editor.isActive("italic"),
                            },
                            on: {
                              click: function ($event) {
                                _vm.editor.chain().focus().toggleItalic().run()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-italic",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", [
                        _c(
                          "a",
                          {
                            class: {
                              "is-active": _vm.editor.isActive("strike"),
                            },
                            on: {
                              click: function ($event) {
                                _vm.editor.chain().focus().toggleStrike().run()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-strikethrough",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", [
                        _c(
                          "a",
                          {
                            class: {
                              "is-active": _vm.editor.isActive("underline"),
                            },
                            on: {
                              click: function ($event) {
                                _vm.editor
                                  .chain()
                                  .focus()
                                  .toggleUnderline()
                                  .run()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-underline",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", [
                        _c(
                          "a",
                          {
                            class: {
                              "is-active": _vm.editor.isActive("blockquote"),
                            },
                            on: {
                              click: function ($event) {
                                _vm.editor
                                  .chain()
                                  .focus()
                                  .toggleBlockquote()
                                  .run()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-quote-right",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", [
                        _c(
                          "a",
                          {
                            class: {
                              "is-active": _vm.editor.isActive("orderedList"),
                            },
                            on: {
                              click: function ($event) {
                                _vm.editor
                                  .chain()
                                  .focus()
                                  .toggleOrderedList()
                                  .run()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-list-ol",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        ),
                      ]),
                      _c("div", [
                        _c("input", {
                          ref: "iFile",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", accept: _vm.acceptFiles },
                          on: { change: _vm.addImage },
                        }),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$refs.iFile.click()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-picture-o",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("editor-content", { attrs: { editor: _vm.editor } }),
          ],
          1
        ),
      ]),
      _c(
        "b-col",
        { staticClass: "pull-right pt-2", attrs: { sm: "4" } },
        [
          _c(
            "b-form-group",
            { staticClass: "my-0" },
            [
              _c(
                "b-button",
                {
                  class: `py-2 px-4 ${_vm.btnMainClass}`,
                  attrs: {
                    type: "button",
                    variant: "outline-primary",
                    block: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-paper-plane",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _c("span", { staticClass: "ml-1" }, [
                    _vm._v(_vm._s(_vm.FormMSG(1, "Comment"))),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }