var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-11" }, [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.comment.message) } }),
      _c(
        "div",
        [
          _c("task-comment-like-reply", {
            attrs: { comment: _vm.comment, "is-root": true },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "pb-4" }),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-1 timeline-comment-action" }, [
      _c("div", { staticClass: "d-flex flex-row" }, [
        _c("div", { staticClass: "col-12 timeline-cursor" }, [
          _c("div", { staticClass: "pull-right" }, [
            _c("i", {
              staticClass: "fa fa-ellipsis-h",
              attrs: { "aria-hidden": "true" },
            }),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }