<template>
	<div>
		<div :class="`d-flex flex-row ${mainClass} pb-3 pt-2`">
			<div class="px-4 text-center" v-if="canDoLike">
				<b-button class="btn-like" :pressed.sync="likeToggle" @click="doLike">
					<i :class="`${likeToggle ? 'fa fa-thumbs-up' : 'fa fa-thumbs-o-up'}`" aria-hidden="true"></i>
				</b-button>
			</div>
			<div v-else class="fix-like-counter">
				<i class="fa fa-thumbs-o-up" aria-hidden="true" v-if="comment.doLike > 0">
					<span class="badge badge-light btn-like-counter">
						{{ comment.doLike }}
					</span>
				</i>
			</div>
			<div class="px-4 text-center">
				<b-button class="btn-reply" :pressed.sync="replyToggle">
					<i class="fa fa-reply" aria-hidden="true"></i><span class="pl-2">{{ FormMSG(1, 'Reply') }}</span>
				</b-button>
			</div>
			<div class="px-4 text-center" v-if="comment.isModified === true">{{ FormMSG(2, 'Modified') }}</div>
		</div>
		<div v-if="replyToggle" class="pb-4">
			<task-editor :task-xid="comment.xid" :reply-to="comment.id" :image-parent-id="comment.imageParentId" :is-root="isRoot" />
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import TaskEditor from '@/components/Task/TaskEditor';
import { store } from '@/store';
import { addUpdateLike, getItemCommentLikeStatus } from '@/cruds/itemcomment.crud';

export default {
	name: 'TaskCommentLikeReply',
	mixins: [languageMessages],
	props: {
		mainClass: {
			type: String,
			required: false,
			default: 'comment-like-parent'
		},
		mainEditorClass: {
			type: String,
			required: false,
			default: 'task-comment-editor px-3 py-3'
		},
		comment: {
			type: Object,
			required: true,
			default: null
		},
		isRoot: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	components: {
		TaskEditor
	},
	data() {
		return {
			likeToggle: false,
			replyToggle: false
		};
	},
	computed: {
		canDoLike() {
			return parseInt(store.state.myProfile.id, 10) !== this.comment.userId;
		}
	},
	async mounted() {
		// console.log('comment from TaskCommentLikeReply: ', this.comment);
		await getItemCommentLikeStatus(this.comment.id).then((r) => {
			this.likeToggle = r;
		});
	},
	methods: {
		async doLike() {
			await addUpdateLike(this.comment.id, this.likeToggle).then((res) => {
				// increment or decrement count
			});
		}
	}
};
</script>
<style lang="scss" scoped></style>
