<template>
	<transition name="fade">
		<div>
			<div class="row">
				<div class="col-10">
					<div class="row">
						<div class="ml-3">
							<div
								:style="{
									backgroundColor: randomizeColor
								}"
								class="not-img"
								v-if="detail.picture === undefined || detail.picture === null || detail.picture === ''"
							>
								<div>
									{{ initial }}
								</div>
							</div>
							<div v-else v-lazy-container="{ selector: 'img' }" class="mb-2">
								<img
									:data-src="showPicture(detail.picture)"
									class="assigned-user-image"
									alt="photo"
									data-error="img/brand/avatar.jpg"
									data-loading="img/brand/tenor.gif"
									style="width: 26px !important; height: 26px !important"
								/>
							</div>
						</div>
						<div class="ml-2 timeline-user">
							<div class="timeline-date-splitter-horizontal">
								<span> {{ detail.firstName }} {{ detail.name | upperCase }} </span>
							</div>
							<div class="timeline-user-date">
								<div>
									{{ dateToLiteral }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-2">
					<div class="pull-right timeline-comment-action">
						<i class="fa fa-ellipsis-h" aria-hidden="true"></i>
					</div>
				</div>
			</div>
			<div class="flex-row mt-2">
				<div class="m-0 timeline-user-msg-without-border" v-html="detail.message"></div>
				<div class="pb-1">
					<task-comment-like-reply :main-class="'comment-like-child'" :comment="detail" :is-root="true" />
				</div>
				<div class="pb-2" style="clear: both" />
			</div>
		</div>
	</transition>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import TaskCommentLikeReply from '@/components/Task/TaskCommentLikeReply';
import { capitalize } from '~utils';
import moment from 'moment';

export default {
	name: 'TaskBlockCommentChild',
	mixins: [languageMessages, globalMixin],
	components: {
		TaskCommentLikeReply
	},
	props: {
		detail: {
			type: Object,
			required: true,
			default: null
		}
	},
	computed: {
		dateToLiteral() {
			const langCurrent = this.currentLang;
			const fullLiteralDate = capitalize(
				moment(this.detail.insertedOn)
					.locale(`${langCurrent}`)
					.format(`dddd Do MMM YYYY [${this.FormMSG(1, 'at')}] HH:mm`)
			);

			return fullLiteralDate;
		},
		initial() {
			return this.detail.firstName.charAt(0).toUpperCase();
		}
	},
	methods: {
		showPicture(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		}
	},
	filters: {
		upperCase(texte) {
			return texte.toUpperCase();
		}
	}
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
