<template>
	<div class="d-inline">
		<task-comment-tree-view :data="data" />
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import TaskCommentTreeView from '@/components/Task/TaskCommentTreeView';
/**
     * 
        [{
            "id": 2,
            "detail": {
                "avatar": null,
                "fullName": "",
                "date": "",
                "comment": ""
            },
            "children": [{
                "id": 4,
                "detail": {
                    "avatar": null,
                    "fullName": "",
                    "date": "",
                    "comment": ""
                },
            },{
                "id": 5,
                "detail": {
                    "avatar": null,
                    "fullName": "",
                    "date": "",
                    "comment": ""
                },
            }]
        }]
     */
export default {
	name: 'TaskTreeCommentChild',
	mixins: [languageMessages],
	props: {
		data: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	components: {
		TaskCommentTreeView
	}
};
</script>
<style lang="sass" scoped></style>
