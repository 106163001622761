var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-10" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "ml-3" }, [
              _vm.detail.picture === undefined ||
              _vm.detail.picture === null ||
              _vm.detail.picture === ""
                ? _c(
                    "div",
                    {
                      staticClass: "not-img",
                      style: {
                        backgroundColor: _vm.randomizeColor,
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\t" +
                            _vm._s(_vm.initial) +
                            "\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  )
                : _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "lazy-container",
                          rawName: "v-lazy-container",
                          value: { selector: "img" },
                          expression: "{ selector: 'img' }",
                        },
                      ],
                      staticClass: "mb-2",
                    },
                    [
                      _c("img", {
                        staticClass: "assigned-user-image",
                        staticStyle: {
                          width: "26px !important",
                          height: "26px !important",
                        },
                        attrs: {
                          "data-src": _vm.showPicture(_vm.detail.picture),
                          alt: "photo",
                          "data-error": "img/brand/avatar.jpg",
                          "data-loading": "img/brand/tenor.gif",
                        },
                      }),
                    ]
                  ),
            ]),
            _c("div", { staticClass: "ml-2 timeline-user" }, [
              _c("div", { staticClass: "timeline-date-splitter-horizontal" }, [
                _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.detail.firstName) +
                      " " +
                      _vm._s(_vm._f("upperCase")(_vm.detail.name)) +
                      " "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "timeline-user-date" }, [
                _c("div", [
                  _vm._v(
                    "\n\t\t\t\t\t\t\t\t" +
                      _vm._s(_vm.dateToLiteral) +
                      "\n\t\t\t\t\t\t\t"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "col-2" }, [
          _c("div", { staticClass: "pull-right timeline-comment-action" }, [
            _c("i", {
              staticClass: "fa fa-ellipsis-h",
              attrs: { "aria-hidden": "true" },
            }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "flex-row mt-2" }, [
        _c("div", {
          staticClass: "m-0 timeline-user-msg-without-border",
          domProps: { innerHTML: _vm._s(_vm.detail.message) },
        }),
        _c(
          "div",
          { staticClass: "pb-1" },
          [
            _c("task-comment-like-reply", {
              attrs: {
                "main-class": "comment-like-child",
                comment: _vm.detail,
                "is-root": true,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "pb-2", staticStyle: { clear: "both" } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }