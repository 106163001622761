var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { class: `d-flex flex-row ${_vm.mainClass} pb-3 pt-2` }, [
      _vm.canDoLike
        ? _c(
            "div",
            { staticClass: "px-4 text-center" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-like",
                  attrs: { pressed: _vm.likeToggle },
                  on: {
                    "update:pressed": function ($event) {
                      _vm.likeToggle = $event
                    },
                    click: _vm.doLike,
                  },
                },
                [
                  _c("i", {
                    class: `${
                      _vm.likeToggle ? "fa fa-thumbs-up" : "fa fa-thumbs-o-up"
                    }`,
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ],
            1
          )
        : _c("div", { staticClass: "fix-like-counter" }, [
            _vm.comment.doLike > 0
              ? _c(
                  "i",
                  {
                    staticClass: "fa fa-thumbs-o-up",
                    attrs: { "aria-hidden": "true" },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "badge badge-light btn-like-counter" },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.comment.doLike) +
                            "\n\t\t\t\t"
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
      _c(
        "div",
        { staticClass: "px-4 text-center" },
        [
          _c(
            "b-button",
            {
              staticClass: "btn-reply",
              attrs: { pressed: _vm.replyToggle },
              on: {
                "update:pressed": function ($event) {
                  _vm.replyToggle = $event
                },
              },
            },
            [
              _c("i", {
                staticClass: "fa fa-reply",
                attrs: { "aria-hidden": "true" },
              }),
              _c("span", { staticClass: "pl-2" }, [
                _vm._v(_vm._s(_vm.FormMSG(1, "Reply"))),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.comment.isModified === true
        ? _c("div", { staticClass: "px-4 text-center" }, [
            _vm._v(_vm._s(_vm.FormMSG(2, "Modified"))),
          ])
        : _vm._e(),
    ]),
    _vm.replyToggle
      ? _c(
          "div",
          { staticClass: "pb-4" },
          [
            _c("task-editor", {
              attrs: {
                "task-xid": _vm.comment.xid,
                "reply-to": _vm.comment.id,
                "image-parent-id": _vm.comment.imageParentId,
                "is-root": _vm.isRoot,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }