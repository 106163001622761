var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.commentType === 1 ||
    (_vm.item.commentType === 0 && _vm.item.replyTo === 0)
    ? _c("div", { staticClass: "block-action-comment pb-2" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "row p-2" }, [
              _c(
                "div",
                { staticClass: "col-sm-6 timeline-name-splitter-horizontal" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "mr-2 pl-2" }, [
                      _vm.item.picture && _vm.item.picture !== ""
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "lazy-container",
                                  rawName: "v-lazy-container",
                                  value: { selector: "img" },
                                  expression: "{ selector: 'img' }",
                                },
                              ],
                              staticClass: "mb-2",
                            },
                            [
                              _c("img", {
                                staticClass: "assigned-user-image",
                                staticStyle: {
                                  width: "26px !important",
                                  height: "26px !important",
                                },
                                attrs: {
                                  "data-src": _vm.showPicture(_vm.item.picture),
                                  alt: "photo",
                                  "data-error": "img/brand/avatar.jpg",
                                  "data-loading": "img/brand/tenor.gif",
                                },
                              }),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "not-img",
                              style: {
                                backgroundColor: _vm.randomizeColor,
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(_vm.initial) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ]
                          ),
                    ]),
                    _c("div", { staticClass: "timeline-height-image" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t" +
                          _vm._s(_vm.item.firstName) +
                          " " +
                          _vm._s(_vm._f("upperCase")(_vm.item.name)) +
                          " "
                      ),
                      _vm.item.commentType === 0
                        ? _c("span", [_vm._v(_vm._s(_vm.commentText))])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "col-sm-6 timeline-date-splitter-horizontal" },
                [
                  _c("div", { staticClass: "pull-right" }, [
                    _vm._v(_vm._s(_vm.dateToLiteral)),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              class: `col-sm-12 px-4${
                _vm.item.commentType === 0 ? " timeline-content" : ""
              }`,
            },
            [
              _vm.item.commentType === 0
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12" } },
                        [
                          _c("task-block-comment-parent", {
                            attrs: { comment: _vm.item },
                          }),
                          _c("task-tree-comment-child", {
                            attrs: { data: _vm.treeData },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", { staticClass: "timeline-text" }, [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.item.message) + "\n\t\t\t"
                    ),
                  ]),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }