<template>
	<li>
		<task-block-comment-child :detail="treeData[detailProp]" v-if="treeData[detailProp]" />
		<ul v-if="treeData[childrenProp] && Array.isArray(treeData[childrenProp])">
			<template v-for="nodeData in treeData[childrenProp]">
				<task-comment-tree-node
					ref="childNodes"
					:data="nodeData"
					:key="nodeData[keyProp]"
					:keyProp="keyProp"
					:xidProp="xidProp"
					:detailProp="detailProp"
					:childrenProp="childrenProp"
				/>
			</template>
		</ul>
	</li>
</template>
<script>
import TaskBlockCommentChild from '@/components/Task/TaskBlockCommentChild';
import { isNil } from '~utils';

export default {
	name: 'TaskCommentTreeNode',
	components: {
		TaskBlockCommentChild
	},
	props: {
		data: {
			type: Object,
			required: true
		},
		detailProp: {
			type: String,
			default: 'detail'
		},
		keyProp: {
			type: String,
			default: 'id'
		},
		xidProp: {
			type: String,
			default: 'xid'
		},
		imageParentIdProp: {
			type: String,
			default: 'imageParentId'
		},
		imageParentTypeProp: {
			type: String,
			default: 'imageParentType'
		},
		imageParentSubTypeProp: {
			type: String,
			default: 'imageParentSubType'
		},
		childrenProp: {
			type: String,
			default: 'children'
		}
	},
	data() {
		return {
			treeData: []
		};
	},
	filters: {
		trim(text) {
			return text.trim();
		}
	},
	methods: {
		getChildNodes() {
			return this.$refs.childNodes || [];
		},
		onPushNode(payload) {
			if (parseInt(this.treeData.id, 10) === parseInt(payload.parentId, 10)) {
				this.appendChild(payload);
			}
		},
		deleteChildNode(childNodeData) {
			let children = this.treeData[this.childrenProp];
			let idx = children.indexOf(childNodeData);
			children.splice(idx, 1);
		},
		appendChild(data) {
			if (this.treeData[this.childrenProp] === undefined) {
				this.$set(this.treeData, this.childrenProp, []);
			}

			let node = {};
			node[`${this.keyProp}`] = parseInt(data.node.id, 10);
			node[`${this.xidProp}`] = data.node.xid;
			node[`${this.imageParentIdProp}`] = data.imageParentId;
			node[`${this.imageParentTypeProp}`] = data.imageParentType;
			node[`${this.imageParentSubTypeProp}`] = data.imageParentSubType;
			node[`${this.detailProp}`] = data.node;

			const index = this.treeData[this.childrenProp].findIndex((td) => td.id === parseInt(data.node.id, 10));
			if (index === -1) {
				this.treeData[this.childrenProp].push(node);
			}
		}
	},
	updated() {
		this.$bus.$on('pushNode', this.onPushNode);
	},
	watch: {
		data: {
			handler(n) {
				if (!isNil(n)) {
					setTimeout(() => {
						this.treeData = n;
						// console.log('nodeMap: ', this.treeData);
					}, 250);
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
